<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text {{ page.title }} - Buy Amazon Inc.
        .container.text-right.gap-bottom
            .content.mt-5()
                .header-text
                    h1.big-text
                        | {{ page.header }}
                .body-text
                    h3.big-text.orange-text &nbsp;&nbsp;
                        | {{ page.content }}
                    .mt-5.big-text(v-html="page.bottom")
                .bottom-text
                    router-link.go-bck(:to="'/'") לחזרה לדף הראשי, לחץ כאן


</template>


<style lang="scss">
    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }
        }


        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }
    }

    .container {

        .content {
            display: flex;
            justify-content: center;
            text-align: right;
            flex-direction: column;


            h1.big-text {
                font-size: 64px;
            }

            h3.big-text {
                font-size: 36px;
            }

            div.big-text {
                font-size: 24px;
            }

            .bottom-text {
                padding-top: 5rem;
                text-align: center;

                .go-bck {
                    box-shadow: inset 0px 1px 10px 0px #fff6af;
                    background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
                    background-color: #ffec64;
                    border-radius: 6px;
                    border: 1px solid #ffaa22;
                    display: inline-block;
                    cursor: pointer;
                    color: #333333;
                    font-family: Arial;
                    font-size: 19px;
                    font-weight: bold;
                    padding: 6px 24px;
                    text-decoration: none;
                    text-shadow: 0px 1px 0px #ffee66;

                    &:hover {
                        background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
                        background-color: #ffab23;
                    }

                    &:active {
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }

        @media only screen and (max-width: 767px) {
            .gap-bottom {
                padding-bottom: 6rem;
                width: 100% !important;
            }
        }
    }
</style>


<script lang="js">
    export default {
        beforeRouteEnter(to, from, next) {
            if (to.name === 'thankyou') {
                if (to.query.type === 'contact-us') {
                    next(vm => {
                        vm.page = {
                            title: 'צור קשר',
                            header: 'תודה רבה שפנית אלינו,',
                            content: ' נשמח לעזור לכם בכל נושא',
                            bottom: 'מייד ישלח מייל עם העתק השאלה להמשך הטיפול. <br> נציגינו יצרו איתך קשר בהקדם האפשרי'
                        }
                    })
                } else if (to.query.type === 'purchase') {
                    next(vm => {
                        vm.page = {
                            title: 'אישור הזמנה',
                            header: 'אנחנו מברחים אותך על הקניה',
                            content: '',
                            bottom: ''
                        }
                    })
                } else {
                    window.location.href = '/404';
                }
            } else window.location.href = '/404';
        },
        data() {
            return {
                page: {
                    title: '',
                    header: '',
                    content: '',
                    bottom: '',
                }
            }
        }
    }
</script>
